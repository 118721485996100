console.log('spide.js loaded');
import Splide from '@splidejs/splide';

let splide = new Splide( '.opi-slider', {
    type   : 'loop',
    rewind: false,
    clones: 3,
    perPage: 3,
    gap: "50px", 
    focus  : 'center',
    pagination: true,
    arrows: false,
    updateOnMove: true,
    breakpoints: {
        980: {
			perPage: 1,
		},		
  }
  } );
  
  splide.mount();
